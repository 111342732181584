<template>
	<div class="cooperation-case">
		<top-nav :img-list="banner" class="top-nav" white>
			<div class="banner-item-title" style="text-align: center" v-html="static.banner.title"></div>
			<span class="banner-item-brief" style="width: 1280px;text-align: center" v-html="static.banner.brief"></span>
		</top-nav>
		<div class="block-area pt-100px pb-80px">
			<div class="block-content">
				<block-title :data="static.title1"></block-title>
				<card-list :list="block1" style="margin-top: 80px;"></card-list>
			</div>
		</div>
		<div class="block-area use-bg pt-80px pb-40px">
			<div class="block-content">
				<block-title :data="static.title2"></block-title>
				<div class="cd-list" style="margin-top: 80px;">
					<div class="cd-item" v-for="(t, i) in block2" :key="i">
						<img :src="t.icon" alt="">
						<div class="context">
							<div class="c-title">{{ t.title }}</div>
							<div class="c-description-inline">{{ t.description }}</div>
							<div class="c-description">{{ t.description }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="block-area pt-80px pb-80px">
			<div class="block-content">
				<block-title :data="static.title3"></block-title>
				<case-list :list="block3"></case-list>
			</div>
		</div>
		<my-footer></my-footer>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import CardList from "../../components/card-list/index.vue";

export default {
	name: "cooperation-case",
	components: { CardList },
	data: function () {
		return {
			banner: require('./assets/cooperation-case-banner.webp'),
			defines: {
				block1: [
					{
						title: '自有研发团队',
						description: '我们拥有丰富的行业经验和专业知识，能够迅速应对市场变化和客户需求，提供高效、可靠的技术解决方案。',
						icon: require('./assets/cc-b1-0.png'),
						contentWidth: '60%',
						style: {
							height: window.flexible.px2rem(193)
						}
					},
					{
						title: '产品丰富',
						description: '提供智能领域的设备、数据、平台、应用，具备丰富的产线、产量支持和完善的售后服务通道。',
						icon: require('./assets/cc-b1-1.png'),
						contentWidth: '60%',
						style: {
							height: window.flexible.px2rem(193)
						}
					},
					{
						title: '营销体系健全',
						description: '形成了“品牌+研发+生产+渠道”完整链条，拥有成熟的销售体系。',
						icon: require('./assets/cc-b1-2.png'),
						contentWidth: '60%',
						style: {
							height: window.flexible.px2rem(193)
						}
					},
					{
						title: '运维服务高效',
						description: '提供7X24小时的机器人后台管控、运输调度、保养与维修服务，降低自主运维的成本。',
						icon: require('./assets/cc-b1-3.png'),
						contentWidth: '60%',
						style: {
							height: window.flexible.px2rem(193)
						}
					},
				],
				block2: [
					{
						title: '水电站北斗高精度变形监测项目',
						icon: require('./assets/cc-b2-0.png'),
						description: '本项目基于北斗卫星导航系统的高精度变形监测技术应用，形成一套“平战结合、有效管用、稳定可靠、经济便捷”的北斗高精度监测系统，水平和垂直位移精度分别达到2mm和3mm，实现全天候、不间断、高精度变形监测，满足水电站大坝运行安全监督管理应用要求。'
					},
					{
						title: '危化车车辆监控项目',
						icon: require('./assets/cc-b2-1.png'),
						description: '利用北斗高精度定位技术，同时结合GIS、车载视频智能分析以及电子铅封锁等技术，实现对危化车运输全程位置的精准跟踪，有效降低危化品运输安全风险。'
					},
					{
						title: '智慧桥梁安全监测',
						icon: require('./assets/cc-b2-2.png'),
						description: '2020年10月，云凤项目已发布了蓝色预警、黄色预警和橙色预警，“得到了相关主管部门及业主单位的重视，达到了监测目的，对现场的实施处置起到数据支撑、监测验证作用。'
					},
					{
						title: '数字孪生智慧矿山',
						icon: require('./assets/cc-b2-3.png'),
						description: '通过在隐患点布设一体化智能监测站，对隐患灾体的地表变形、深部变形地表裂缝、地下水位、降雨量等进行实时监测，在智能网关进行边缘计算后，将数据上传云平台，进行远程在线实时地灾监测、分析和预警。'
					},
					{
						title: '云凤高速K36边坡监测',
						icon: require('./assets/cc-b2-4.png'),
						description: '以北斗高精度定位和时空智能技术为核心，融合云计算、智能物联网、高精度地图等技术，建立适用桥梁的综合性安全监测平台，对于桥梁监测做到信息化管理、数字化监测、智能化分析。'
					},
					{
						title: '昆明经开区国土局地灾隐患点监测',
						icon: require('./assets/cc-b2-5.png'),
						description: '融合云计算、大数据、物联网、地理信息系统、数字孪生等技术，整合矿山区域的信息资源，按照安防、车辆、能源和应急等业务一体化设计理念，实现多方位全覆盖、动态在线精准监管、信息资源实时共享。以“一张图”为载体，将矿山区域的安全、环境状况和能源消耗等进行综合展示，强化矿山区域安全运维保障能力，促进矿山区域良性可持续发展。'
					},
					{
						title: '石坝社区小石坝崩塌',
						icon: require('./assets/cc-b2-6.png'),
						description: '灭失矿山原采石坑监测'
					},
					{
						title: '昆明经开区',
						icon: require('./assets/cc-b2-7.png'),
						description: '小石牛采矿区灭失矿山监测'
					},
				],
				block3: [
					{
						name: '智慧水利',
						button: '了解更多',
						onClick: () => this.$router.push('/smart-water-conservancy'),
						img: require('./assets/cc-b3-0.png')
					},
					{
						name: '智慧矿山',
						button: '了解更多',
						onClick: () => this.$router.push('/smart-quarry'),
						img: require('./assets/cc-b3-1.png')
					},
					{
						name: '智慧港口',
						button: '了解更多',
						onClick: () => this.$router.push('/smart-port'),
						img: require('./assets/cc-b3-2.png')
					},
					{
						name: '智慧交通',
						button: '了解更多',
						onClick: () => this.$router.push('/smart-traffic'),
						img: require('./assets/cc-b3-3.png')
					},
					{
						name: '智慧城市',
						button: '了解更多',
						onClick: () => this.$router.push('/smart-city'),
						img: require('./assets/cc-b3-4.png')
					},
					{
						name: '智慧桥梁',
						button: '了解更多',
						onClick: () => this.$router.push('/smart-city/bridge-monitor'),
						img: require('./assets/cc-b3-5.png')
					},
				]
			}
		}
	},
	computed: {
		...mapGetters(["globalData"]),
		static() {
			return this.globalData['cooperation-case']
		},
		block1() {
			return window.deepMerge(this.defines.block1, this.static.block1)
		},
		block2() {
			return window.deepMerge(this.defines.block2, this.static.block2)
		},
		block3() {
			return window.deepMerge(this.defines.block3, this.static.block3)
		},
	},
}
</script>

<style scoped lang="scss">
::v-deep(.now-button) {
	display: none
}
.use-bg {
	background: url("@/views/smart-water-conservancy/assets/sm-b2.png") center no-repeat;
	background-size: cover;
}

.cd-list {
	display: grid;
	grid-template-columns: repeat(2, 620px);
	justify-content: space-between;
	grid-auto-rows: 480px;
	row-gap: 40px;
	.cd-item {
		position: relative;
		img {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	}
	.cd-item:hover .context {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background: rgba(78, 153, 253, .8);
		.c-description-inline {
			display: none;
		}
		.c-title {
			color: white;
		}
		.c-description {
			color: white;
			display: block;
		}
	}
	.context {
		position: absolute;
		left: 0;
		bottom: 0;
		height: 104px;
		width: 100%;
		overflow: hidden;
		background: rgba(white, 0.8);
		border-radius: 2px 2px 2px 2px;
		padding: 24px 0;
		box-sizing: border-box;
		transition: all .2s;

		.c-title {
			font-family: Source Han Sans CN, Source Han Sans CN;
			font-weight: 500;
			font-size: 24px;
			color: #333333;
			white-space: nowrap;
			padding: 0 24px;
			box-sizing: border-box;
			transition: all .2s;
		}
		.c-description-inline, .c-description {
			font-family: Source Han Sans CN, Source Han Sans CN;
			font-weight: 400;
			font-size: 16px;
			color: #666666;
			padding: 0 24px;
			box-sizing: border-box;
			margin-top: 16px;
			transition: all .2s;
		}
		.c-description-inline {
			width: 100%;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		.c-description {
			display: none;
		}
	}
}
</style>
