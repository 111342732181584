import Vue from 'vue';
import VueRouter from 'vue-router';
import Index from '@/views/index.vue';
import SmartPort from '@/views/smart-port/index.vue'
import PortResolution from '@/views/smart-port/port-resolution.vue'
import LocationResolution from '@/views/smart-port/location-resolution.vue'
import SmartTraffic from '@/views/smart-traffic/index.vue'
import SwiftResolution from "../views/smart-traffic/swift-resolution.vue";
import PatrolResolution from "../views/smart-traffic/patrol-resolution.vue";
import MonitorResolution from '@/views/smart-traffic/monitor-resolution.vue'
import EnforceResolution from '@/views/smart-traffic/enforce-resolution.vue'
import Product from '@/views/product.vue'
import Service from '@/views/service.vue'
import GetPlan from "../views/get-plan.vue";
import About from "../views/about.vue";
import SmartWaterConservancy from "../views/smart-water-conservancy/index.vue";
import SecurityMonitoring from "../views/smart-water-conservancy/security-monitoring.vue";
import ReservoirProject from "../views/smart-water-conservancy/reservoir-project.vue";
import WaterEnvironment from "../views/smart-water-conservancy/water-environment.vue";
import SmartQuarry from "../views/smart-quarry/index.vue";
import DigitalQuarry from "../views/smart-quarry/digital-quarry.vue";
import QuarryMonitor from "../views/smart-quarry/quarry-monitor.vue";
import CarMonitor from "../views/smart-quarry/car-monitor.vue";
import SmartCity from "../views/smart-city/index.vue";
import LifeLine from "../views/smart-city/life-line.vue";
import BridgeMonitor from "../views/smart-city/bridge-monitor.vue";
import DisasterMonitor from "../views/smart-city/disaster-monitor.vue";
import PipeGallery from "../views/smart-city/pipe-gallery.vue";
import CooperationCase from "../views/cooperation-case/index.vue";
import NotFound from "../views/notFound.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		component: Index,
	},
	{
		path: '/smart-port',
		component: SmartPort
	},
	{
		path: '/smart-port-resolution',
		component: PortResolution,
	},
	{
		path: '/smart-location-resolution',
		component: LocationResolution,
	},
	{
		path: '/smart-traffic',
		component: SmartTraffic,
	},
	{
		path: '/smart-swift-resolution',
		component: SwiftResolution,
	},
	{
		path: '/smart-patrol-resolution',
		component: PatrolResolution,
	},
	{
		path: '/smart-monitor-resolution',
		component: MonitorResolution,
	},
	{
		path: '/smart-enforce-resolution',
		component: EnforceResolution,
	},
	{
		path: '/product',
		component: Product,
	},
	{
		path: '/service',
		component: Service,
	},
	{
		name: 'get-plan',
		path: '/get-plan',
		component: GetPlan,
	},
	{
		path: '/about',
		component: About,
	},
	{
		name: 'smart-water-conservancy',
		path: '/smart-water-conservancy',
		component: SmartWaterConservancy,
	},
	{
		name: 'security-monitoring',
		path: '/smart-water-conservancy/security-monitoring',
		component: SecurityMonitoring
	},
	{
		name: 'reservoir-project',
		path: '/smart-water-conservancy/reservoir-project',
		component: ReservoirProject
	},
	{
		name: 'water-environment',
		path: '/smart-water-conservancy/water-environment',
		component: WaterEnvironment
	},
	{
		name: 'smart-quarry',
		path: '/smart-quarry',
		component: SmartQuarry
	},
	{
		name: 'digital-quarry',
		path: '/smart-quarry/digital-quarry',
		component: DigitalQuarry
	},
	{
		name: 'quarry-monitor',
		path: '/smart-quarry/quarry-monitor',
		component: QuarryMonitor
	},
	{
		name: 'car-monitor',
		path: '/smart-quarry/car-monitor',
		component: CarMonitor
	},
	{
		name: 'smart-city',
		path: '/smart-city',
		component: SmartCity
	},
	{
		name: 'life-line',
		path: '/smart-city/life-line',
		component: LifeLine
	},
	{
		name: 'bridge-monitor',
		path: '/smart-city/bridge-monitor',
		component: BridgeMonitor
	},
	{
		name: 'disaster-monitor',
		path: '/smart-city/disaster-monitor',
		component: DisasterMonitor
	},
	{
		name: 'pipe-gallery',
		path: '/smart-city/pipe-gallery',
		component: PipeGallery
	},
	{
		name: 'cooperation-case',
		path: '/cooperation-case',
		component: CooperationCase
	},
	// {
	// 	name: '404',
	// 	path: '/404',
	// 	component: NotFound
	// },
	{
		path: "*",
		redirect: '/'
	}
]

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

let router = new VueRouter({
	routes,
});

router.beforeEach((to, from, next) => {
	next()
})

router.afterEach(() => {
	window.scrollTo({
		top: 0,
		left: 0,
		behavior: "smooth"
	})
})

export default router
