<template>
  <div>
    <top-nav class="nav" :imgList="banner" white hideButton>
      <div class="en-bannerTxt">{{ globalData.getPlan.title }}</div>
      <div class="bannerTxt">{{ globalData.getPlan.brief }}</div>
    </top-nav>
    <div class="hover-pop">
      <!-- <div class="hover-pop-back"></div> -->
      <div class="hover-pop-content">
        <!-- <div class="hover-pop-title">填写信息获取解决方案</div> -->
        <div class="hover-pop-item">
          <img class="hover-pop-icon hover-pop-name" src="" alt="" />
          <div class="hover-pop-txt">姓名</div>
          <input
            class="hover-pop-input hover-pop-name-input"
            type="text"
            placeholder="请输入您的姓名"
          />
        </div>
        <div class="hover-pop-item">
          <img class="hover-pop-icon hover-pop-call" src="" alt="" />
          <div class="hover-pop-txt">联系方式</div>
          <input
            class="hover-pop-input hover-pop-call-input"
            type="text"
            placeholder="请输入您的手机号"
          />
        </div>
        <div class="hover-pop-item">
          <img class="hover-pop-icon hover-pop-company" src="" alt="" />
          <div class="hover-pop-txt">公司名称</div>
          <input
            class="hover-pop-input hover-pop-company-input"
            type="text"
            placeholder="请输入您的公司名称"
          />
        </div>
        <div class="hover-pop-item">
          <img class="hover-pop-icon hover-pop-plan" src="" alt="" />
          <div class="hover-pop-txt">方案类型</div>
          <select
            class="hover-pop-input hover-pop-plan-input"
            placeholder="请选择您的方案类型"
            :style="{ color: selected ? '#666' : '#ccc' }"
          >
            <option selected disabled value="">请选择您的方案类型</option>
            <option value="智慧水利">智慧水利</option>
            <option value="智慧矿山">智慧矿山</option>
            <option value="智慧港口">智慧港口</option>
            <option value="智慧交通">智慧交通</option>
            <option value="智慧城市">智慧城市</option>
          </select>
        </div>
        <div class="hover-pop-item">
          <img class="hover-pop-icon hover-pop-demand" src="" alt="" />
          <div class="hover-pop-txt">需求描述</div>
          <textarea
            class="hover-pop-input hover-pop-area"
            placeholder="请留下您的具体需求，我们将在第一时间联系您~"
          ></textarea>
        </div>
        <div class="hover-pop-item">
          <img class="hover-pop-check-icon" src="" alt="" />
          <input
            class="hover-pop-input hover-pop-check-input"
            type="text"
            placeholder="请输入验证码"
          />
        </div>

        <div class="private" v-if="false">资料收集<span class="sp">《隐私说明》</span></div>
        <div class="hover-pop-button">免费获取报价方案</div>
      </div>

      <div class="privateBox">
        <div class="private-back"></div>
        <div class="private-content">
          <div class="private-title">
            中航北斗用户隐私说明
            <img class="private-close" alt="" />
          </div>
          <div class="private-inner">
            <div class="private-h1">
              我们深知个人信息对您的重要性，我们将按照法律法规规定为您提供更加安全、可靠的服务。本摘要希望向您简洁介绍《中航北斗隐私政策》的核心内容。
            </div>
            <div class="private-h2">1、个人信息收集和使用</div>
            <div class="private-h3">主要功能/场景收集和使用</div>
            <div class="private-h3">
              为了实现特定业务功能，我们需要收集和使用你的部分个人信息，你有权拒绝我们收集和使用你的个人信息。但请理解，如你拒绝我们收集和使用业务功能所必需的个人信息，则将无法享受对应的业务功能。
            </div>
            <div class="private-h2">2、您如何管理自己的信息</div>
            <div class="private-h3">通过产品页面自主操作：</div>
            <div class="private-h3">
              登录中航北斗官网，进入【方案获取】页面，用户主动填写基础信息；
            </div>
            <div class="private-h2">3、联系我们</div>
            <div class="private-h3">
              您可以通过在线客服或400-007-8188电话联系我们，或选择给我们发送邮件（发送邮件至scjhy2012@163.com或写信至以下地址：上四川省成都市高新区天府四街199号长虹科技大厦12楼1号、13楼1号。一般情况下，我们将在5个工作日内回复。
            </div>

            <div class="private-button">我已知晓</div>
          </div>
        </div>
      </div>

      <!-- <div class="hover-pop-success">
        <img class="hover-pop-close hover-pop-close2" alt="" />

        <img class="hover-pop-success-img" src="" alt="" />
        <div class="hover-pop-success-title">提交成功</div>
        <div class="hover-pop-success-subtitle">
          请保持联系方式通畅，我们将会在1小时内与您联系！
        </div>
        <div class="hover-pop-success-info">
          <img class="hover-pop-success-phone" src="" alt="" />
          咨询电话:
          <div class="hover-pop-success-sp">400-007-8188</div>
        </div>
        <div class="hover-pop-success-info">
          (8:00-18:00)
          <div class="hover-pop-success-sp">133-5035-8828</div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  computed: {
    ...mapGetters(["globalData"]),
  },
  data() {
    return {
      banner: require("@/asset/get-plan/ic_plan_01.png"),
      selected: false,
    };
  },
  mounted() {
    getCode();
    // let pop = document.querySelector(".hover-pop");
    // let back = document.querySelector(".hover-pop-back");
    // let successContent = document.querySelector(".hover-pop-success");

    var checkImg = document.querySelector(".hover-pop-check-icon");
    checkImg.addEventListener("click", () => {
      getCode();
    });

    var content = document.querySelector(".hover-pop-content");
    content.style.background = `url(${window.origin}/static-images/back.png) no-repeat center 100%`;
    var popName = document.querySelector(".hover-pop-name");
    popName.src = `${window.origin}/static-images/ic_user.png`;
    var popCall = document.querySelector(".hover-pop-call");
    popCall.src = `${window.origin}/static-images/ic_phone.png`;
    var popCompany = document.querySelector(".hover-pop-company");
    popCompany.src = `${window.origin}/static-images/ic_company.png`;
    var popPlan = document.querySelector(".hover-pop-plan");
    popPlan.src = `${window.origin}/static-images/ic_plan.png`;
    var popDemand = document.querySelector(".hover-pop-demand");
    popDemand.src = `${window.origin}/static-images/ic_des.png`;

    // var readPrivate = document.querySelector(".private").querySelector(".sp");
    var close = document.querySelector(".private-close");
    close.src = `${window.origin}/static-images/ic_close.png`;
    var confirm = document.querySelector(".private-button");
    var privateBox = document.querySelector(".privateBox");

    // readPrivate.addEventListener("click", () => {
    //   privateBox.style.display = "block";
    // });
    close.addEventListener("click", () => {
      privateBox.style.display = "none";
    });
    confirm.addEventListener("click", () => {
      privateBox.style.display = "none";
    });

    let name = document.querySelector(".hover-pop-name-input");
    let call = document.querySelector(".hover-pop-call-input");
    let company = document.querySelector(".hover-pop-company-input");
    let plan = document.querySelector(".hover-pop-plan-input");
    let check = document.querySelector(".hover-pop-check-input");
    let demand = document.querySelector(".hover-pop-area");
    let submit = document.querySelector(".hover-pop-button");

    if (Object.keys(this.$route.params).length > 0) {
      name.value = this.$route.params.name || "";
      call.value = this.$route.params.mobile || "";
      company.value = this.$route.params.company || "";
      plan.value = this.$route.params.plan || "";
      if (plan.value) {
        this.selected = true;
      }
      demand.value = this.$route.params.remark || "";
    }

    plan.addEventListener("change", () => {
      this.selected = true;
    });
    submit.addEventListener("click", () => {
      sendInfo();
    });
    function getCode() {
      axios
        .get("https://api.dev.zhkcjt.com/support/other/captcha")
        .then((res) => {
          checkImg.src =
            "data:image/svg+xml;charset=utf-8," + encodeURIComponent(res.data);
        });
    }

    function sendInfo() {
			const tips = [];
			const emptyValue = [];
			if (!name.value) {
				emptyValue.push('姓名');
			}
			if (!call.value) {
				emptyValue.push('联系方式');
			}
	    emptyValue.length && tips.push(`请填写 ${emptyValue.join('、')}`);
			if (call.value.length !== 11) {
				tips.push('请输入正确的手机号');
			}
			if (tips.length) {
				return alert(tips.join('\n'));
			}
      axios
        .post(
          `https://api.dev.zhkcjt.com/support/user/websiteCommit/${check.value}`,
          {
            source:GB_SOURCE || '未知',
            name: name.value,
            mobile: call.value,
            remark: demand.value,
            company: company.value,
            plan: plan.value,
            type: "city",
          }
        )
        .then((res) => {
          if (res.data.status !== 1) {
            getCode();
            alert("验证码有误，请重新输入");
          } else {
            alert("资料提交成功!");
            window.history.go(0);
            // content.style.display = "none";
            // successContent.style.display = "block";
          }
        });
    }
    getCode();
    // let successImg = document.querySelector(".hover-pop-success-img");
    // successImg.src = `${window.origin}/static-images/success.png`;
    // let successPhone = document.querySelector(".hover-pop-success-phone");
    // successPhone.src = `${window.origin}/static-images/phone.png`;
    // let close1 = document.querySelector(".hover-pop-close1");
    // close1.src = `${window.origin}/static-images/close.png`;

    // let close2 = document.querySelector(".hover-pop-close2");
    // close2.src = `${window.origin}/static-images/close.png`;
    // close1.addEventListener("click", close);
    // close2.addEventListener("click", close);
    // back.addEventListener("click", close);
    // function close() {
    //   pop.style.display = "none";
    //   content.style.display = "block";
    //   successContent.style.display = "none";
    //   name.value = "";
    //   call.value = "";
    //   check.value = "";
    //   demand.value = "";
    // }
  },
};
</script>
<style lang="scss" scoped>
:deep(.nav) {
	height: 2.645833rem!important;
	.content-limit {
		height: calc(100% - 1.52083333rem);
		.txt {
			margin-top: 0.2rem;
		}
	}
}
.hover-pop {
  //   display: none;
}
.hover-pop-back {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(10, 10, 10, 0.6);
  z-index: 999;
}
.hover-pop-content {
  width: 800px;
  //   height: 612px;
  padding: 33px 120px;
  box-sizing: border-box;
  z-index: 999;
  position: absolute;
  left: 50%;
	top: 340px;
  transform: translateX(-50%);
  background: white;
  border-top-left-radius: 8px;
	border-top-right-radius: 8px;
}
.hover-pop-title {
  font-weight: 500;
  color: #3097ef;
  font-size: 36px;
  text-align: center;
  margin-bottom: 60px;
}
.hover-pop-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 12px;
  color: red;
}
.hover-pop-icon {
  width: 20px;
  flex-shrink: 0;
  margin-top: 10px;
}
.hover-pop-txt {
  width: 90px;
  font-size: 20px;
  color: #4e99fd;
  margin: 0 16px 0 8px;
  flex-shrink: 0;
  text-align-last: justify;
  position: relative;
  margin-top: 10px;
}
.hover-pop-txt::after {
  content: ":";
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
}
.hover-pop-input {
  flex-grow: 1;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  border: 1px solid #e8e8e8;
  font-size: 16px;
  height: 40px;
  padding: 0 20px;
  outline: none;
  color: #666;
  &::placeholder {
    color: #cccccc;
  }
}
.hover-pop-check-icon {
  width: 120px;
  height: 40px;
  margin-right: 16px;
  cursor: pointer;
}

// .hover-pop-demand {
//   width: 138px;
//   height: 40px;
//   font-size: 20px;
//   margin-right: 16px;
//   color: rgba(48, 151, 239, 1);
//   text-align: right;
//   position: relative;
//   align-self: flex-start;
// }
// .hover-pop-demand::after {
//   content: ":";
//   position: absolute;
//   left: 100%;
//   top: 50%;
//   transform: translateY(-50%);
//   height: 100%;
// }
.hover-pop-area {
  height: 140px;
  resize: none;
  padding-top: 10px;
  box-sizing: border-box;
}
.hover-pop-button {
  font-size: 24px;
  color: white;
  width: 397px;
  height: 48px;
  background: linear-gradient(180deg, #3197f1 0%, #3384e8 100%);
  border-radius: 8px 8px 8px 8px;
  text-align: center;
  line-height: 48px;
  margin: 0 auto;
  margin-top: 16px;
  cursor: pointer;
}
.hover-pop-success {
  display: none;
  width: 657px;
  height: 520px;
  padding: 40px 0;
  text-align: center;
  box-sizing: border-box;
  z-index: 999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 16px;
}
.hover-pop-success-img {
  width: 180px;
  margin: 0 auto;
}
.hover-pop-success-title {
  color: rgba(29, 218, 103, 1);
  font-size: 36px;
  margin-top: 40px;
}
.hover-pop-success-subtitle {
  color: rgba(102, 102, 102, 1);
  font-size: 16px;
  margin-top: 24px;
  margin-bottom: 85px;
}
.hover-pop-success-info {
  display: flex;
  align-items: center;
  color: rgba(102, 102, 102, 1);
  font-size: 18px;
  justify-content: center;
}
.hover-pop-success-phone {
  margin-right: 8px;
}
.hover-pop-success-sp {
  font-size: 24px;
  color: rgba(48, 151, 239, 1);
  margin-left: 16px;
}
.hover-pop-close {
  position: absolute;
  width: 16px;
  right: 24px;
  top: 24px;
  cursor: pointer;
}
.private {
  font-size: 14px;
  color: #999999;
  text-align: center;
  .sp {
    color: #4e99fd;
    cursor: pointer;
  }
}
.en-bannerTxt {
  font-family: YouSheBiaoTiHei;
  font-size: 60px;
  color: white;
  margin-bottom: 40px;
  text-align: center;
}
.bannerTxt {
  font-size: 30px;
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
}

.privateBox {
  display: none;
  .private-back {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba($color: #000000, $alpha: 0.6);
    z-index: 999;
  }
  .private-content {
    position: fixed;
    width: 800px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    .private-title {
      color: white;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
      background: #4e99fd;
      position: relative;
      .private-close {
        position: absolute;
        right: 24px;
        top: 22px;
        width: 16px;
        cursor: pointer;
      }
    }
    .private-inner {
      font-size: 14px;
      line-height: 2;
      background: white;
      text-align: left;
      padding: 40px;
      padding-top: 10px;
      .private-h1 {
        color: #666;
      }
      .private-h2 {
        color: #333;
      }
      .private-h3 {
        color: #999;
      }
      .private-button {
        width: 144px;
        height: 48px;
        background: linear-gradient(180deg, #4e99fd 0%, #3384e8 100%);
        border-radius: 2px 2px 2px 2px;
        font-size: 20px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        margin-top: 50px;
        cursor: pointer;
      }
    }
  }
}
</style>
