<template>
  <div>
    <top-nav :imgList="banner">
      <div class="banner-item-title">
        {{ globalData.smartSwiftResolution.title }}
      </div>
      <div class="banner-item-brief">
        {{ globalData.smartSwiftResolution.brief }}
      </div>
    </top-nav>
    <div class="weakness">
      <block-title
        :title="globalData.smartSwiftResolution.weakness.top.title"
        :en="globalData.smartSwiftResolution.weakness.top.en"
        :sub="globalData.smartSwiftResolution.weakness.top.sub"
      ></block-title>
      <div class="content-limit">
        <card-list :list="block4" style="margin-top: 80px"></card-list>
      </div>
    </div>
    <div class="structure">
      <block-title
        :title="globalData.smartSwiftResolution.structure.top.title"
        :en="globalData.smartSwiftResolution.structure.top.en"
        :sub="globalData.smartSwiftResolution.structure.top.sub"
      ></block-title>
      <img
        class="img content-limit"
        src="@/asset/smart-traffic/ic_traffic_35.png"
        alt=""
      />
    </div>
    <div class="advantage">
      <block-title
        :title="globalData.smartSwiftResolution.advantage.top.title"
        :en="globalData.smartSwiftResolution.advantage.top.en"
        :sub="globalData.smartSwiftResolution.advantage.top.sub"
      ></block-title>
      <div class="list content-limit">
        <div class="item" v-for="(item, index) of advantage" :key="index">
          <div class="content">
            <div class="outline">
              <img class="icon" :src="item.icon" alt="" />
            </div>
            <div class="title">{{ item.title }}</div>
            <div class="en-title">{{ item.enTitle }}</div>
          </div>
          <div class="hover">
            <div class="hover-title">{{ item.title }}</div>
            <div class="hover-en-title">{{ item.enTitle }}</div>
            <div class="hover-brief">{{ item.brief }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="drive">
      <div class="en-title">{{ globalData.smartSwiftResolution.drive.en }}</div>
      <div class="ch-title">
        {{ globalData.smartSwiftResolution.drive.title }}
      </div>
      <div class="ch-brief">
        {{ globalData.smartSwiftResolution.drive.brief }}
      </div>
      <div class="content content-limit">
        <div class="left">
          <div
            class="item"
            v-for="(item, index) of drive"
            :key="index"
            @click="driveCurrentIndex = index"
          >
            <img
              class="item-icon"
              :src="item.left.activeImg"
              alt=""
              v-if="driveCurrentIndex === index"
            />
            <img class="item-icon" :src="item.left.img" alt="" v-else />
            <div class="item-txt">{{ item.left.txt }}</div>
          </div>

          <div
            class="item-strip"
            :style="{ top: 25 * driveCurrentIndex + '%' }"
          ></div>
        </div>
        <div class="right">
          <template v-for="(item, index) of drive">
            <div class="item" v-if="driveCurrentIndex === index" :key="index">
              <div class="title">{{ item.right.title }}</div>
              <div class="brief">
                {{ item.right.brief }}
              </div>
              <div class="more-button">
                查看详情
                <img
                  src="@/asset/index/ic_index_48.png"
                  class="more-icon"
                  alt=""
                />
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="core">
      <block-title
        :title="globalData.smartSwiftResolution.core.top.title"
        :en="globalData.smartSwiftResolution.core.top.en"
        :sub="globalData.smartSwiftResolution.core.top.sub"
      ></block-title>
      <div class="content-limit">
        <card-list :list="block3" style="margin-top: 80px"></card-list>
      </div>
    </div>

    <div class="footer-resolution">
      <div class="block-content layout">
        <div class="left">
          <div class="f-title">
            {{ globalData.smartSwiftResolution.footer.title }}
          </div>
          <div class="f-en">
            {{ globalData.smartSwiftResolution.footer.en.toUpperCase() }}
          </div>
          <div class="f-sub">
            {{ globalData.smartSwiftResolution.footer.sub }}
          </div>
        </div>
        <div class="right">
          <div class="ask-btn">在线咨询</div>
        </div>
      </div>
    </div>

    <my-footer></my-footer>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import CardList from "../../components/card-list/index.vue";

export default {
  computed: {
    ...mapGetters(["globalData"]),
    block4() {
      return window.deepMerge(
        [
          {
            title: "跨部门统筹难度大",
            description:
              "智慧交通的业务比较分散，造成各部门间的信息传递滞后甚至是出现偏差，在后期的建设过程中统筹难度很大",
            icon: require("@/asset/smart-traffic/ic_traffic_31.png"),
          },
          {
            title: "综合应用水平需进一步提高",
            description:
              "数字信息化几乎只应用于业务部门，无法获取整体数据并进行深入分析，致使智慧交通的综合应用水平不高",
            icon: require("@/asset/smart-traffic/ic_traffic_32.png"),
          },
          {
            title: "需求碎片化",
            description:
              "不同场景的应用需求也有所不同，这导致智慧交通市场的需求是较为碎片化的，智慧交通的方案提供商们不得不定制专属服务，从而导致投入成本过高，不利于行业发展",
            icon: require("@/asset/smart-traffic/ic_traffic_33.png"),
          },
          {
            title: "重建设轻运维",
            description:
              "相关部门更重视新项目的建设而不是对建成的智慧项目进行维护，这就导致“重建设轻运维”成为了行业性的问题",
            icon: require("@/asset/smart-traffic/ic_traffic_34.png"),
          },
        ],
        this.globalData.smartSwiftResolution.weakness.block4
      );
    },
    advantage() {
      return window.deepMerge(
        [
          {
            icon: require("@/asset/smart-traffic/ic_traffic_30.png"),
            title: "北斗智能底座",
            enTitle: "Beidou Intelligent Base",
            brief:
              "北斗高精度定位的专有智能服务、海量高精度定位终端、定制化高精度地图，全链路自主知识产权的产品和服务，实现交通全要素时空信息采集，构建业务全流程的数字底座。",
          },
          {
            icon: require("@/asset/smart-traffic/ic_traffic_23.png"),
            title: "数字决策分析",
            enTitle: "Digital Decision Analysis",
            brief:
              "宏-中-微观真实数据的自动标定，建立数字孪生模型，实现真实路面交通和虚拟环境的深度融合，进行不同场景仿真还原与预测，交通车流变化演、路线动态规划等。",
          },
          {
            icon: require("@/asset/smart-traffic/ic_traffic_24.png"),
            title: "智能管控",
            enTitle: "intelligent control",
            brief:
              "基于数字孪生模型、智能算法，自动生成对策方案，通过诱导、限速、控流等多方式，协同实现高速公路运行效率提升和安全控制，为管控决策提供全面支持。",
          },
          {
            icon: require("@/asset/smart-traffic/ic_traffic_25.png"),
            title: "协同交互",
            enTitle: "Collaborative interaction",
            brief:
              "路侧引导设施、地图平台、车道级移动交互平台（APP）等多渠道协同交互， 伴随式精准信息发布，触达用户，打造互联网 ＋ 智慧高速的典型应用场景。",
          },
        ],
        this.globalData.smartSwiftResolution.advantage.list
      );
    },
    drive() {
      return window.deepMerge(
        [
          {
            left: {
              img: require("@/asset/smart-traffic/ic_traffic_39.png"),
              activeImg: require("@/asset/smart-traffic/ic_traffic_37.png"),
            },
            right: {},
          },
          {
            left: {
              img: require("@/asset/smart-traffic/ic_traffic_41.png"),
              activeImg: require("@/asset/smart-traffic/ic_traffic_44.png"),
            },
            right: {},
          },
          {
            left: {
              img: require("@/asset/smart-traffic/ic_traffic_42.png"),
              activeImg: require("@/asset/smart-traffic/ic_traffic_45.png"),
            },
            right: {},
          },
          {
            left: {
              img: require("@/asset/smart-traffic/ic_traffic_38.png"),
              activeImg: require("@/asset/smart-traffic/ic_traffic_40.png"),
            },
            right: {},
          },
        ],
        this.globalData.smartSwiftResolution.drive.list
      );
    },
    block3() {
      return window.deepMerge(
        [
          {
            title: "高精度实时位置查看",
            description: "可实时查看精确到亚米级的人员，实现快速的精确追踪",
            icon: require("@/asset/smart-port/ic_port_42.png"),
          },
          {
            title: "高精地图可视化",
            description:
              "定制开发带有业务场景的高精度专属地图，快速加载并与公共地图无缝拼接",
            icon: require("@/asset/smart-port/ic_port_43.png"),
          },
          {
            title: "人员行动轨迹查询",
            description:
              "支持人员行动轨迹在高精度地图上的可视化展示，真实还原人员的行动轨迹",
            icon: require("@/asset/smart-port/ic_port_44.png"),
          },
          {
            title: "禁入区域管理",
            description:
              "其于电子围栏技术，设置禁入区域、作业区域，可支持闯入报警、靠近报警、超时作业等",
            icon: require("@/asset/smart-port/ic_port_45.png"),
          },
          {
            title: "视频联动",
            description:
              "接入现场视频监控，实现定位与视频联动，便于及时掌握现场状况",
            icon: require("@/asset/smart-port/ic_port_46.png"),
          },
          {
            title: "智能告警",
            description:
              "sos一键呼救、禁区报警、轨迹纠偏等情况及时响应，快速处理",
            icon: require("@/asset/smart-port/ic_port_47.png"),
          },
        ],
        this.globalData.smartSwiftResolution.core.block3
      );
    },
  },
  components: {
    CardList,
  },
  data() {
    return {
      banner: require("@/asset/smart-traffic/ic_traffic_01.png"),

      driveCurrentIndex: 0,
    };
  },
};
</script>
<style lang="scss" scoped>
.weakness {
  padding: 100px 0 80px;
}
.structure {
  padding: 80px 0;
  background: url(@/asset/smart-traffic/ic_traffic_36.png) no-repeat
    center/cover;
  .img {
    display: block;
    margin-top: 40px;
  }
}
.advantage {
  padding: 80px 0;
  .list {
    display: flex;
    gap: 24px;
    margin-top: 80px;
    .item {
      width: 300px;
      height: 480px;
      border-radius: 8px 8px 8px 8px;
      border: 1px solid rgba(51, 51, 51, 0.2);
      position: relative;
      .content {
        text-align: center;
        padding: 40px;
        transition: all 0.5s;
        position: relative;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        .outline {
          width: 70px;
          height: 70px;
          position: relative;
          margin: 0 auto;
          .icon {
            width: 36px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
          &::after {
            content: "";
            border: 1px solid rgba(51, 51, 51, 0.1);
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            transform: rotate(45deg);
          }
        }
        .title {
          font-size: 24px;
          color: #333333;
          margin-top: 50px;
          margin-bottom: 8px;
        }
        .en-title {
          font-size: 18px;
          color: #999999;
        }
        &::after {
          content: "";
          position: absolute;
          left: 50%;
          bottom: 90px;
          transform: translateX(-50%);
          width: 74px;
          height: 8px;
          background: #4e99fd;
          border-radius: 7px 7px 7px 7px;
        }
      }
      .hover {
        position: absolute;
        opacity: 0;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        padding: 40px;
        box-sizing: border-box;
        transition: all 0.5s;
        background: linear-gradient(
            rgba(78, 153, 253, 0.6),
            rgba(78, 153, 253, 0.6)
          ),
          url(@/asset/smart-traffic/ic_traffic_90.webp) no-repeat center/cover;
        .hover-title {
          font-size: 28px;
          color: white;
          margin-bottom: 8px;
        }
        .hover-en-title {
          font-size: 18px;
          color: rgba(255, 255, 255, 0.6);
          margin-bottom: 40px;
        }
        .hover-brief {
          font-size: 16px;
          color: white;
          line-height: 2;
        }
      }
      &:hover {
        .content {
          opacity: 0;
        }
        .hover {
          opacity: 1;
        }
      }
	    &:nth-child(2) .hover {
		    background: linear-gradient(
				    rgba(78, 153, 253, 0.6),
				    rgba(78, 153, 253, 0.6)
		    ),
		    url('@/asset/smart-traffic/ic_traffic_87.webp') no-repeat center/cover;
	    }
	    &:nth-child(3) .hover {
		    background: linear-gradient(
				    rgba(78, 153, 253, 0.6),
				    rgba(78, 153, 253, 0.6)
		    ),
		    url('@/asset/smart-traffic/ic_traffic_88.webp') no-repeat center/cover;
	    }
	    &:nth-child(4) .hover {
		    background: linear-gradient(
				    rgba(78, 153, 253, 0.6),
				    rgba(78, 153, 253, 0.6)
		    ),
		    url('@/asset/smart-traffic/ic_traffic_89.webp') no-repeat center/cover;
	    }
    }
  }
}
.drive {
  line-height: 1;
  padding: 80px 0;
  transition: 0.5s all;
  background: url(@/asset/smart-traffic/ic_traffic_43.png) no-repeat
    center/cover;
  .en-title {
    font-size: 36px;
    color: rgba(255, 255, 255, 0.4);
    margin-bottom: 10px;
    text-align: center;
  }
  .ch-title {
    font-size: 36px;
    color: white;
    margin-bottom: 34px;
    text-align: center;
    font-family: YouSheBiaoTiHei;
  }
  .ch-brief {
    font-size: 20px;
    color: rgba(255, 255, 255, 0.6);
    text-align: center;
    margin-bottom: 80px;
  }
  .content {
    display: flex;
    height: 420px;
    .left {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      border-right: 2px solid rgba($color: white, $alpha: 0.6);
      flex-shrink: 0;
      .item {
        display: flex;
        align-items: center;
        gap: 8px;
        padding-right: 40px;
        cursor: pointer;
        .item-icon {
          width: 20px;
        }
        .item-txt {
          font-size: 20px;
          font-weight: bold;
          color: white;
        }
      }
      .item-strip {
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        width: 4px;
        height: 25%;
        transition: all 0.5s;
        margin-right: -1px;
        background: #4e99fd;
      }
    }

    .right {
      padding-left: 80px;
      .item {
        .title {
          font-size: 36px;
          color: white;
        }
        .brief {
          font-size: 20px;
          line-height: 2;
          margin: 40px 0;
          color: rgba($color: white, $alpha: 0.6);
        }
      }
    }
  }
}
.more-button {
  width: 217px;
  height: 61px;
  background: #4e99fd;
  border-radius: 2px 2px 2px 2px;
  font-size: 24px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  cursor: pointer;
  .more-icon {
    width: 30px;
  }
}
.core {
  padding: 80px 0;
}
.footer-resolution {
  height: 304px;
  background-size: cover;

  .layout {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 20px;
  }
  .f-title {
    font-family: YouSheBiaoTiHei, YouSheBiaoTiHei;
    font-weight: 400;
    font-size: 36px;
    color: #333333;
    margin-top: 60px;
  }
  .f-en {
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 500;
    font-size: 24px;
    color: #999999;
    margin-top: 10px;
  }
  .f-sub {
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 400;
    font-size: 24px;
    color: #999999;
    margin-top: 40px;
  }
  .ask-btn {
    width: 200px;
    height: 60px;
    background: linear-gradient(180deg, #4e99fd 0%, #5482fa 100%);
    box-shadow: 3px 3px 8px 1px #e8e8e8;
    border-radius: 2px 2px 2px 2px;
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 400;
    font-size: 24px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
  }
}
</style>
